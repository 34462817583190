















































import { Component, Vue } from 'vue-property-decorator'
import { Form } from '@/components/boulder/create/index'
import { Select } from '@/components/form'
import { BoulderForm } from '@/domain/boulder'
import LoadingCircle from '@/components/LoadingCircle.vue'
import { showSnackbar, SNACKBAR } from '@/infrastructure/EventBus/global-actions/showSnackbar'
import { getModule } from 'vuex-module-decorators'
import { BoulderStore } from '@/infrastructure/store/boulders'
import Layout from '@/components/layout.vue'
import { consume, PATH, provide } from '@/utils/provide-consum-decorator'
import store from '@/infrastructure/store'
import { SectorStore } from '@/infrastructure/store/sector'
import { Sector } from '@/domain/sector'
import { IBoulderStore } from '@/infrastructure/store/boulders/types'
import { ISectorStore } from '@/infrastructure/store/sector/types'
import CanvasDrawer from '@/components/boulder/canvas/canvas-drawer.vue'
import { SectorId } from '@/domain/sector/sector-id'
import { sectorImages } from '@/components/image-loader'

type SectorItem = { text: string; value: string }

@Component({
    components: {
        CanvasDrawer,
        Layout,
        LoadingCircle,
        createBoulderForm: Form,
        sectorSelect: Select
    }
})
@provide({
    [PATH.BOULDER_API]: () => {
        return getModule(BoulderStore, store)
    },
    [PATH.SECTOR_API]: () => {
        return getModule(SectorStore, store)
    }
})
export default class index extends Vue {
    @consume(PATH.BOULDER_API) boulders!: IBoulderStore
    @consume(PATH.SECTOR_API) sectors!: ISectorStore

    sector = ''

    async onSubmit(boulder: BoulderForm): Promise<void> {
        try {
            const result = await this.boulders.createBoulder(boulder)

            showSnackbar({
                type: SNACKBAR.SUCCESS,
                message: `Boulder "${result.name}" wurde angelegt`
            })
        } catch (e) {
            console.log(e)
            showSnackbar({
                type: SNACKBAR.ERROR,
                message: `Boulder konnte nicht angelegt werden`
            })
        }
    }

    src(id: SectorId): string {
        return sectorImages.find((image) => image.id === id.asString())?.src || ''
    }

    get sectorItems(): SectorItem[] {
        return this.sectors.all.map((sector: Sector) => ({
            value: sector.id.asString(),
            text: sector.name
        }))
    }

    onGoBack(): void {
        if (!this.sector) {
            this.$router.push({ name: 'boulder-list' })

            return
        }

        this.sector = ''
    }

    async mounted(): Promise<void> {
        await this.sectors.loadSectors()
    }
}
