import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import { ISectorStore } from '@/infrastructure/store/sector/types'
import { Sector } from '@/domain/sector'
import { consume, PATH, PROVIDER, provideVuex } from '@/utils/provide-consum-decorator'
import { InMemorySectorApi } from '@/application/InMemorySectorApi'
import { SectorApi } from '@/domain/sector/sector-api'

@Module({ dynamic: true, store, name: 'sector', namespaced: true })
@provideVuex({ [PATH.SECTOR_API]: () => new InMemorySectorApi(null) }, PROVIDER.SECTOR)
export class SectorStore extends VuexModule implements ISectorStore {
    @consume(PATH.SECTOR_API, PROVIDER.SECTOR) sectorService!: SectorApi

    sectors: Sector[] = []

    @Mutation
    add(sector: Sector): void {
        this.sectors = [...this.sectors, sector]
    }

    get all(): Sector[] {
        return this.sectors
    }

    @Mutation
    setSectors(sectors: Sector[]): void {
        this.sectors = sectors
    }

    @Action
    async loadSectors(): Promise<void> {
        const sectors = await this.sectorService.list()

        this.setSectors(sectors)
    }
}
