import { SectorApi } from '@/domain/sector/sector-api'
import { Sector } from '@/domain/sector'
import { SectorId } from '@/domain/sector/sector-id'
import { sectorImages } from '@/components/image-loader'

export class InMemorySectorApi implements SectorApi {
    constructor(sectors: Sector[] | null) {
        if (sectors !== null) {
            this.sectors = sectors
        }
    }

    private readonly sectors: Sector[] = sectorImages.map((sector) => ({
        name: sector.name,
        id: SectorId.fromString(sector.id),
        src: sector.src,
        active: true
    }))

    async list(): Promise<Sector[]> {
        return this.sectors
    }
}
