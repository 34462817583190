




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { Select, TextInput, TextArea, HoldSelector } from '@/components/form'
import Button from '@/infrastructure/ui/button.vue'
import { SubmitBoulder } from '@/components/boulder/types'
import { Item, Rules } from '@/components/form/types'
import { grades, GradeType } from '@/domain/boulder/grades'
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_TITLE } from '@/components/boulder/create/index'
import { category, CategoryType } from '@/domain/boulder/categories'
import { HOLD, Hold } from '@/domain/boulder/hold'

@Component({
    components: {
        textField: TextInput,
        singleSelect: Select,
        textArea: TextArea,
        holdSelector: HoldSelector,
        customButton: Button
    }
})
export default class Form extends Vue {
    @Prop({ type: Function, required: true }) submit!: SubmitBoulder
    @Prop({ type: String, required: true }) sector!: string

    loading = false
    valid = true
    name = ''
    description = ''
    grade: GradeType | '' = ''
    category: CategoryType | '' = ''
    holds: Hold[] = []
    holdErrors: string[] = []

    @Watch('holds')
    onHoldsChange(): void {
        if (this.holdErrors.length > 0) {
            this.validateHolds()
        }
    }

    grades(): Array<Item> {
        return grades.asItems()
    }

    categories(): Array<Item> {
        return category.asItems()
    }

    nameRules(): Rules {
        return [
            (value) => (!!value && value.length > 0) || 'Bitte gib deinem Boulder einen Namen',
            (value: string) => value.length < MAX_LENGTH_TITLE || `Maximale Titellänge: ${MAX_LENGTH_TITLE} Zeichen`
        ]
    }

    gradeRules(): Rules {
        return [(value: string) => (!!value && value.length > 0) || 'Bitte bewerte deinen Boulder']
    }

    descriptionRules(): Rules {
        return [
            (value: string) =>
                value.length < MAX_LENGTH_DESCRIPTION ||
                `Maximale Beschreibungslänge: ${MAX_LENGTH_DESCRIPTION} Zeichen`
        ]
    }

    categoryRules(): Rules {
        return [(value: string) => (!!value && value.length > 0) || 'Bitte wähle eine Kategorie']
    }

    validate(): void {
        this.valid = true
        const form = this.$refs.form as HTMLFormElement
        form.validate()
        this.validateHolds()
    }

    validateHolds(): void {
        let errors: string[] = []
        const typeMap = {
            [HOLD.START]: 0,
            [HOLD.BETWEEN]: 0,
            [HOLD.TOP]: 0
        }

        this.holds.forEach((hold) => typeMap[hold.type]++)

        if (typeMap[HOLD.START] < 1) {
            errors = [...errors, 'START fehlt']
            this.valid = false
        }

        if (typeMap[HOLD.TOP] < 1) {
            errors = [...errors, 'TOP fehlt']
            this.valid = false
        }

        this.holdErrors = errors
    }

    async onSubmit(): Promise<void> {
        this.validate()

        if (!this.valid || !this.grade || !this.category) {
            return
        }

        this.loading = true

        await this.submit({
            name: this.name,
            description: this.description || '',
            grade: this.grade,
            category: this.category,
            sectorId: this.sector,
            holds: [
                ...this.holds.map((hold) => ({
                    ...hold,
                    x: Math.round(hold.x),
                    y: Math.round(hold.y)
                }))
            ]
        })

        this.loading = false
        await this.$router.push({ name: 'boulder-list' })
    }
}
